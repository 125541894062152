import user from './user';
import property from './property';
import accountsAdmin from './accountsAdmin';
import transaction from './transaction';
import persistent from './persistent';
import VuexPersistence from 'vuex-persist';
import router from '@/router';
import axios from 'axios';

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ['user', 'property', 'transaction', 'persistent']
});

const errorGeneric = 'Could not fetch user data, your information might not be up to date';
const errorMaintenance = 'MyAccount is currently under maintenance. You will be redirected in 10 seconds...';

const defaultState = {
	online: true,
	clearing: false,
	loggingOut: false,
	underMaintenance: null,
	upcomingMaintenance: null,
	globalError: '',
	fetchedIdentities: false
};

export default {
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		user,
		property,
		transaction,
		accountsAdmin,
		persistent,
	},
	state() {
		return Object.assign({}, defaultState);
	},
	getters: {
		onlineStatus: state => {
			return state.online;
		},
		clearingStatus: state => {
			return state.clearing;
		},
		loggingOutStatus: state => {
			return state.loggingOut;
		},
		underMaintenance: state => {
			return state.underMaintenance;
		},
		upcomingMaintenance: state => {
			return state.upcomingMaintenance;
		}
	},
	mutations: {
		setOnlineStatus(state, status) {
			state.online = status;
		},
		setGlobalError(state, status) {
			state.globalError = status;
		},
		setClearing(state, status) {
			state.clearing = status;
		},
		setLoggingOut(state, status) {
			state.loggingOut = status;
		},
		resetMaintenance(state) {
			state.underMaintenance = null;
		},
		setMaintenance(state, status) {
			state.underMaintenance = status;
		},
		resetUpcomingMaintenance(state) {
			state.upcomingMaintenance = null;
		},
		setUpcomingMaintenance(state, status) {
			state.upcomingMaintenance = status;
		},
		fetchedIdentities(state) {
			state.fetchedIdentities = true;
		},
		clear(state) {
			Object.assign(state, defaultState);
		}
	},
	actions: {
		async clear({commit}) {
			commit('clear');
			commit('property/clear');
			commit('transaction/clear');
			commit('user/clear');
		},
		async checkMaintenance({state, commit}) {
			return new Promise((resolve, reject) => {
				axios.get(`${process.env.VUE_APP_API_ENDPOINT}/maintenance`).then((response) => {
					if (response.data.hasOwnProperty('maintenance')) {
						commit('setMaintenance', response.data.maintenance);
						commit('setUpcomingMaintenance', response.data.upcoming);
						resolve({maintenance: state.underMaintenance, upcoming: state.upcomingMaintenance});
						return;
					}

					commit('resetMaintenance');
					commit('resetUpcomingMaintenance');
					reject(null);

				}).catch((e) => {
					reject(e);
				});
			});
		},
		async handleError({commit, dispatch}, {status, resource}) {
			// Log out deleted users
			if (status === 404) {
				switch (resource) {
				case `${process.env.VUE_APP_API_ENDPOINT}/user`:
				case 'User':
					dispatch('user/logout', false);
					break;
				default:
					return;
				}
			}
			if (status < 500)
				return;
			if (status === 503) {
				// Call checkMaintenance to set the maintenance message for the maintenance view
				await this.dispatch('checkMaintenance').catch(() => {
					// Couldn't check maintenance, consider it's off.
				});

				commit('setGlobalError', errorMaintenance);
				setTimeout(() => {
					commit('setGlobalError', '');
					router.push({name: 'maintenance'});
				}, 10 * 1000);
				return;
			}
			// Handle any other 50x and trigger the global error for specific endpoints or grapqhQL query names only
			switch (resource) {
			case `${process.env.VUE_APP_API_ENDPOINT}/user`:
			case 'User':
				commit('setGlobalError', errorGeneric);
				break;
			default:
				return;
			}
		}
	},
	plugins: [vuexLocal.plugin]
};
