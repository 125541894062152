import { nextTick } from 'vue';

const alphaNumericalCleanup = (value) => {
	return value
		.replace(/\\/g, '/')
		.replace(/[’"]/g, '\'')
		.replace(/[—–]/g, '-');
};

const capitalize = (value) => {
	if (!value) return '';
	value = value.toString().toLowerCase().replace( /\b./g, function(a) {
		return a.toUpperCase();
	});
	return value;
};

// validate a long crn or generate a long crn from a short crn
const crnCheck = (value) => {
	if (! /^\d{8}$|\d{15}$/.test(value) ) return '';
	let bEven = false, generate = false, nCheck = 0;
	if(value.length === 8){
		value = `10${value}0000`;
		generate = bEven = true;
	}

	//Luhn Algorithm.
	for (var n = value.length - 1; n >= 0; n--) {
		var cDigit = value.charAt(n),
			nDigit = parseInt(cDigit, 10);
		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
		nCheck += nDigit;
		bEven = !bEven;
	}
	return generate ? `${value}${(1000 - nCheck) % 10}` : (nCheck % 10) === 0;
};

// converts date from YYYY-MM-DD (DD/MM/YYYY default)
const formatDate = (value, format = '') => {
	if(!value) {
		return 'N/A';
	}
	let date = new Date(value);
	let formattedDate = value;
	if (isFinite(date)) {
		// force two digit format for day, month and minutes
		let day = (date.getDate() < 10 ? '0' : '') + date.getDate();
		let month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
		let minutes = ((date.getMinutes()) < 10 ? '0' : '') + (date.getMinutes());
		switch (format) {
		case 'dateTime':
			// date + time (24h format)
			formattedDate = `${day}/${(month)}/${date.getFullYear()} ${date.getHours()}:${minutes}`;
			break;
		default:
			// date only
			formattedDate = `${day}/${(month)}/${date.getFullYear()}`;
			break;
		}
	}
	return formattedDate;
};

//format value into aud currency
const formatMonetaryIntl = (value) => new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value);

const formatMonetary = (value, type = '') => {
	if (isNaN(value) ) return value;
	if (!type) return formatMonetaryIntl(value);
	const creditSign = 'CR';
	let formattedValue = 0;
	const intlAbsValue = formatMonetaryIntl( Math.abs(value));
	switch (type) {
	case 'transaction':
		formattedValue = (value < 0 ? `${creditSign} ` : '') + intlAbsValue;
		break;
	case 'balance' :
		formattedValue = intlAbsValue + (value < 0 ? ` ${creditSign}` : '');
		break;
	}
	return formattedValue;
};

const errorFocus = (selector) => {
	nextTick(() => {
		parent = selector ? document.querySelector(selector) : document;
		if (parent) {
			let invalidElt = parent.querySelector('[aria-invalid]');
			if (invalidElt) {
				invalidElt.focus();
			}
		}
	});
};

const elementFocus = (ref) => {
	nextTick(() => {
		if (ref) {
			// ref could be a Vue component, get the corresponding DOM element
			if (!ref.tagName) {
				ref = ref.$el;
			}
			ref.scrollIntoView();
			ref.focus();
		}
	});
};

const expiredPrint = (date) => {
	// some bills are never printed
	// this is used to check if they were emitted more than 10 days ago (can be combined with no due date or no printed date, depending on usage)
	const billDate = new Date(date);
	const printDate = billDate.getTime() + 86400000*10;
	return (Date.now() > printDate);
};

const sanitize = (string) => {
	if (!string) {
		return '';
	}
	const regex = /(<([^>]+)>)/ig;
	return string.replace(regex, '');
};

export { alphaNumericalCleanup, capitalize, crnCheck, formatDate, formatMonetary, errorFocus, elementFocus, expiredPrint, sanitize };
