import gql from 'graphql-tag';

export const GET_ACCOUNT = gql`
	query Account($crn: String!, $name: String!, $postCode: String!) {
		account(crn: $crn, name: $name, postCode: $postCode) {
			isPrivate
			address
			entity {
				entityName
				entityReference
				uniqueId
			}
			persons {
				personReference
				personName
				personRole
				claimed
			}
		}
	}
`;

export const ADD_PROPERTY = gql`
	mutation AddAccount($prn: String!, $entity: String!, $crn: String!, $name: String!, $postCode: String!) {
		addAccount(prn: $prn, entity: $entity, crn: $crn, name: $name, postCode: $postCode) {
			id
		}
	}
`;

export const REMOVE_PROPERTY = gql`
	mutation removeAccount($crn: String!) {
		removeAccount(crn: $crn) {
			id
		}
	}
`;

export const GET_BILLS = gql`
	query Bills($crn: String!) {
		bills(crn: $crn) {
			uniqueId
			billNumber
			datePrinted
			dateBilled
			billTotal
			dateDue
			billReasonCode
			billedStateCode
		}
	}
`;

export const GET_DOWNLOADABLE_BILLS = gql`
	query DownloadableBills($crn: String!) {
		downloadableBills(crn: $crn) {
			hubId
			billNumber
			billAmount
			billDate
			billDue,
			billType
		}
	}
`;

export const GET_TRANSACTIONS = gql`
	query Transactions($crn: String!) {
		transactions(crn: $crn) {
			uniqueId
			transactionValue
			postedDate
			transactionTypeCode,
			billNumber
		}
	}
`;

export const UPDATE_BILLING_METHOD = gql`
	mutation updateBillingMethod(
		$crn: String!
		$switchTo: String!
		$contact: String
	) {
		updateBillingMethod(
			crn: $crn
			switchTo: $switchTo
			contact: $contact
		) {
			id
		}
	}
`;

export const UPDATE_PROPERTY_NAME = gql`
	mutation updatePropertyName(
		$crn: String!
		$prn: String!
		$name: String
	) {
		updatePropertyName(
			crn: $crn
			prn: $prn
			name: $name
		) {
			id
		}
	}
`;

export const UPDATE_BILLING_ADDRESS = gql`
	mutation updateCustomerAddress(
		$crn: String!
		$australianAddress: AustralianCustomerAddressType
		$overseasAddress: OverseasCustomerAddressType
	) {
		updateCustomerAddress(
			crn: $crn
			australianAddress: $australianAddress
			overseasAddress: $overseasAddress
		) {
			id
		}
	}
`;

export const VERIFY_ADDRESS = gql`
	query addressFinder($addressId: String!) {
		addressFinder(addressId: $addressId)
	}
`;


export const AUTOCOMPLETE_ADDRESS = gql`
	query addressAutoComplete($query: String!) {
		addressAutoComplete(query: $query) {
			id
			canonical_address_id
			full_address
		}
	}
`;

export const UPDATE_PROPERTY_ORDER = gql`
	mutation updatePropertyOrder($crns: [String!]!) {
		updatePropertyOrder(crns: $crns) {
			id
		}
	}
`;

export const UPDATE_PROPERTY_NOTIFICATIONS = gql`
	mutation updatePropertyNotifications($crn: String!, $prn: String!, $value: Boolean!) {
		updatePropertyNotifications(crn: $crn, prn: $prn, value: $value)
	}
`;

export const SETUP_DIRECT_DEBIT = gql`
	mutation setupDirectDebit(
		$crn: String!
		$bsb: String!
		$accountNumber: String!
		$accountName: String!
	) {
		setupDirectDebit(
			crn: $crn
			bsb: $bsb
			accountNumber: $accountNumber
			accountName: $accountName
		) {
			id
			amount
			overdue
			date
			showAmountAndDate
		}
	}
`;

export const CANCEL_DIRECT_DEBIT = gql`
	mutation cancelDirectDebit(
		$crn: String!
	) {
		cancelDirectDebit(
			crn: $crn
		) {
			id
		}
	}
`;
