import { GET_ACCOUNT, ADD_PROPERTY, UPDATE_BILLING_METHOD, UPDATE_BILLING_ADDRESS, REMOVE_PROPERTY, UPDATE_PROPERTY_NAME, UPDATE_PROPERTY_ORDER, UPDATE_PROPERTY_NOTIFICATIONS, SETUP_DIRECT_DEBIT, CANCEL_DIRECT_DEBIT } from '../graphql/queries/property.js';
import { apolloClient } from '@/graphql/apollo';
import { customMap, event, pageview } from 'vue-gtag';

const initAdd = {
	step: 0,
	crn: '',
	name: '',
	postcode: '',
	property: null,
	address: null,
	persons: null
};

const defaultState = {
	loaded: false,
	all: {},
	orderedCRNList: [],
	add: initAdd
};

export default {
	namespaced: true,

	state() {
		return Object.assign({}, defaultState);
	},
	mutations: {
		storeProperties(state, value) {
			state.loaded = true;
			state.all = value;
		},
		clearProperties(state) {
			state.loaded = false;
			state.all = {};
		},
		storePropertiesOrderedList(state, value) {
			state.orderedCRNList = value;
		},
		storePayment(state, payload) {
			state.all[payload.id][payload.type === 'bill' ? 'billPayment' : 'payment'] = {time: payload.time, balance: payload.balance, url: payload.url};
		},
		updateAddPropertyStatus(state, value) {
			state.add = value;
		},
		updateStep(state, value) {
			state.add.step = value;
		},
		addReset(state) {
			state.add = initAdd;
		},
		clear(state) {
			Object.assign(state, defaultState);
		},
		remove(state, crn) {
			delete state.all[crn];
			state.orderedCRNList = state.orderedCRNList.filter(orderedCRN => orderedCRN !== crn);
		}
	},
	getters: {
		billingAddress: (state) => (id, linebreak = '\n') => {
			if (!state.all[id]) {
				return '';
			}
			let property = state.all[id].nonEBContact;
			let billingAddressArray = [];
			for (var k = 1; k <= 5; k++) {
				property['billingAddressLine' + k] && billingAddressArray.push(property['billingAddressLine' + k]);
			}
			let billingAddress = billingAddressArray.join(linebreak);
			billingAddress += (property.postcode) ? ',' + property.postcode : '';

			return billingAddress;
		},
		propertyAddress: (state) => (id, linebreak = '\n') => {
			if (!state.all[id]) {
				return '';
			}
			let property = state.all[id].customerAddress;
			let entityAddressArray = [];
			for (var i = 1; i <= 5; i++) {
				property['customerAddressLine' + i] && entityAddressArray.push(property['customerAddressLine' + i]);
			}
			return entityAddressArray.join(linebreak);
		},
		propertyById: (state) => (id) => {
			return state.all[id];
		},
		billingSettings: (state) => (id, type) => {
			if (!state.all[id]) {
				return false;
			}
			return state.all[id].customerClass.includes(type);
		},
		billingEmail: (state) => (id) => {
			return state.all[id]?.billingContactEmail || '';
		},
		billingPhone: (state) => (id) => {
			return state.all[id]?.billingContactPhone || '';
		},
		all: (state) => {
			return state.all;
		},
		prnNb: (state) => {
			let prns = {};
			for (const [key, value] of Object.entries(state.all)) {
				prns[value.personReference] = true;
			}
			return Object.entries(prns).length;
		},
		loaded: (state) => {
			return state.loaded;
		},
		orderedCRNList: (state) => {
			return state.orderedCRNList;
		},
		emailNotifications: (state) => (crn) => {
			if (!state.all[crn]) {
				return true;
			}
			return state.all[crn].emailNotifications;
		}
	},
	actions: {
		async createProperties({commit}, properties) {
			let propertyObj = {};
			let commercial = 0;
			let domestic = 0;
			for (const property of properties) {
				propertyObj[property.customerReference] = property;
				if (property.customerClass.includes('commercial')) {
					commercial++;
				} else {
					domestic++;
				}
			}

			commit('storePropertiesOrderedList', properties.map(p => p.customerReference));
			commit('storeProperties', propertyObj);

			customMap({'dimension1': 'commercial'});
			event('commercial_dimension', {commercial: commercial});
			customMap({'dimension2': 'domestic'});
			event('domestic_dimension', {domestic: domestic});
		},
		async check({state, commit}, values) {
			const defaultErrorMessage = 'Something went wrong, please try again.<br>If the problem persists, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0010</b>.';
			let crn8 = values.crn.replace(/\s/g, '');

			if (crn8.length === 15) {
				crn8 = crn8.substring(2, 10);
			}
			if (crn8.length !== 8) {
				throw ({error: defaultErrorMessage});
			}

			await apolloClient.query({
				query: GET_ACCOUNT,
				fetchPolicy: 'network-only',
				variables: {crn: crn8, name: values.name.replace(/’/g, '\''), postCode: values.postcode},
			}).then((response) => {
				let accountData = response.data.account;
				switch (true) {
				case (!!accountData.address):
					values['address'] = accountData.address;
					values['property'] = accountData.entity;
					values['step'] = state.add.step + 1;
					values['crn8'] = crn8;
					// values.crn and values.postcode are also passed to store
					let personArray = {};
					for (const person of accountData.persons) {
						personArray[person.personReference] = person;
					}
					values['persons'] = personArray;
					commit('updateAddPropertyStatus', values);
					break;

				default: // server sent 200 but we couldn't find any valid address record and it's not set to private
					throw ({error: defaultErrorMessage});
				}
			}).catch((err) => {
				if (err.networkError) {
					switch (err.networkError.statusCode) {
					case 401:
						pageview({
							page_title: 'Add a property - Invalid',
							page_path: '/property/add/enter-details#invalid'
						});
						throw ({error: 'We could not find any matching property, please check your details and try again.<br>If you are having difficulties adding your property, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0001</b>.'});
					case 403:
						pageview({
							page_title: 'Add a property - PIE',
							page_path: '/property/add/enter-details#pie'
						});
						throw ({error: 'This account cannot be accessed.<br>Please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0002</b>.'});
					case 404:
						pageview({
							page_title: 'Add a property - Empty Commercial',
							page_path: '/property/add/enter-details#empty-commercial'
						});
						throw ({error: 'To manage this property, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0003</b> to request authority on the account.'});
					case 409:
						throw ({error: 'This property already exists in your account, <a href="/">go back to Dashboard</a>'});
					case 428:
						pageview({
							page_title: 'Add a property - Empty Domestic',
							page_path: '/property/add/enter-details#empty-domestic'
						});
						throw ({error: 'To manage this property, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0004</b> to request authority on the account.'});
					case 429:
						throw ({error: 'You are going a bit fast. Please wait a minute and try again. <br>If you are having difficulties adding your property, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a>.'});
					default:
						throw ({error: defaultErrorMessage});
					}
				}
			});
		},
		async add({state}, values) {
			const defaultErrorMessage = 'Something went wrong, please try again later.';
			const response = await apolloClient.mutate({
				mutation: ADD_PROPERTY,
				variables: {
					prn: values.prn,
					entity: values.entity,
					name: values.name.replace(/’/g, '\''),
					crn: values.crn,
					postCode: values.postcode
				},
			}).catch((err) => {
				if (err.networkError) {
					switch (err.networkError.statusCode) {
					case 409:
						throw ({error: 'This property has already been added to your account, or the name you selected has already been picked by another user of MyAccount. It could be you under a different signon. If you believe you don\'t have another account, or have forgotten your login details, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0011<b>.'});
					case 429:
						throw ({error: 'You are going a bit fast. Please wait a minute and try again. <br>If you are having difficulties adding your property, please <a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">contact us</a> and quote <b>ERROR 0012<b>.'});
					default:
						throw ({error: defaultErrorMessage});
					}
				}
			});
			return !!response;
		},
		async remove({commit}, payload) {
			let success = false;
			const response = await apolloClient.mutate({
				mutation: REMOVE_PROPERTY,
				variables: {
					crn: payload.crn
				},
			}).catch((err) => {
				return false;
			});
			if (response) {
				if (response.data) {
					pageview({
						page_title: 'Remove a property',
						page_path: '/property#remove'
					});

					if (payload.beforeRemove) {
						payload.beforeRemove();
					}
					commit('remove', payload.crn);
					success = true;
				}
			}
			return success;
		},
		async updateBillingMethod({state}, values) {
			const response = await apolloClient.mutate({
				mutation: UPDATE_BILLING_METHOD,
				variables: {
					crn: values.crn,
					switchTo: values.switchTo,
					contact: values.contact
				},
			}).catch((err) => {
			});
			if (response) {
				if (response.data) {
					await this.dispatch('user/fetch').catch(err => {
						// TODO display error if fetch fails after updating billing method?
					});
				}
			}
			return !!response;
		},
		async updateBillingAddress({state}, updateAddress) {

			const response = await apolloClient.mutate({
				mutation: UPDATE_BILLING_ADDRESS,
				variables: updateAddress
			}).catch((err) => {
			});
			if (response) {
				if (response.data) {
					await this.dispatch('user/fetch').catch(err => {
						// TODO display error if fetch fails after updating billing address?
					});
				}
			}
			return !!response;
		},
		async updatePropertyName({state}, payload) {
			const response = await apolloClient.mutate({
				mutation: UPDATE_PROPERTY_NAME,
				variables: payload,
			}).catch(err => {
				return false;
			});

			if (response) {
				await this.dispatch('user/fetch').catch(err => {
					// exception handled via globalError, no specific local error message here
				});
			}
			return !!response;
		},
		async updateOrder({commit}, orderedCRNs) {
			commit('storePropertiesOrderedList', orderedCRNs);
			const response = await apolloClient.mutate({
				mutation: UPDATE_PROPERTY_ORDER,
				variables: {
					crns: orderedCRNs
				}
			}).catch((err) => {
			});

			return !!response;
		},
		async updateNotifications({state, commit}, payload) {
			const response = await apolloClient.mutate({
				mutation: UPDATE_PROPERTY_NOTIFICATIONS,
				variables: {
					crn: payload.crn,
					prn: payload.prn,
					value: payload.value
				}
			}).catch((err) => {
				throw ({error: true});
			});

			if (response) {
				await this.dispatch('user/fetch').catch(err => {
					// exception handled via globalError, no specific local error message here
				});
			}

			return !!response;
		},
		async setupDirectDebit({state, commit}, payload) {
			const response = await apolloClient.mutate({
				mutation: SETUP_DIRECT_DEBIT,
				variables: {
					crn: payload.crn,
					bsb: payload.bsb,
					accountNumber: payload.accountNumber,
					accountName: payload.accountName
				}
			}).catch((err) => {
				// Populate response.status for timeouts

				const status = (!err.networkError || !err.networkError.response) ? 500 : err.networkError.response.status;
				throw ({error: status});
			});

			await this.dispatch('user/fetch').catch(err => {
				// exception handled via globalError, no specific local error message here
			});

			return response.data.setupDirectDebit;
		},
		async cancelDirectDebit({state, commit}, payload) {
			const response = await apolloClient.mutate({
				mutation: CANCEL_DIRECT_DEBIT,
				variables: {
					crn: payload.crn
				}
			}).catch((err) => {
				const status = (!err.networkError || !err.networkError.response) ? 500 : err.networkError.response.status;
				throw ({error: status});
			});

			await this.dispatch('user/fetch').catch(err => {
				// exception handled via globalError, no specific local error message here
			});

			return response.data.cancelDirectDebit;
		}
	}
};
