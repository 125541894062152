const defaultState = {
	survey: true,
	ebReminderDismissed: false,
	propertiesHelpDismissed: false,
	pricingTeaserDismissed: false,
	pricingInfoDismissed: false,
};

export default {
	namespaced: true,

	state() {
		return Object.assign({}, defaultState);
	},
	getters: {
		showEBReminder: state => {
			if (!state.ebReminderDismissed) {
				return true;
			}
			// show EB reminder if dismissed over a month ago (and this property is not already on EB)
			return (Date.now() - state.ebReminderDismissed) / 1000 / 60 / 60 / 24 > 30;
		}
	},
	mutations: {
		setSurvey(state, status) {
			state.survey = status;
		},
		setDismissEBReminder(state) {
			// we let the user dismiss the reminder for all properties at once
			state.ebReminderDismissed = Date.now();
		},
		resetDismissedEBReminder(state) {
			// not really used by the application but necessary to mutate state
			state.ebReminderDismissed = false;
		},
		setPropertiesHelp(state) {
			state.propertiesHelpDismissed = true;
		},
		setPricingTeaser(state) {
			state.pricingTeaserDismissed = true;
		},
		setPricingInfo(state) {
			state.pricingInfoDismissed = true;
		}
	},
	actions: {}
};
