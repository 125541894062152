import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { apolloClient } from './graphql/apollo';
import './registerServiceWorker';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { auth0 } from './auth';
import { createApolloProvider } from '@vue/apollo-option';

const apolloProvider = createApolloProvider({
	defaultClient: apolloClient
});

router.onError(error => {
	if (/Loading .+ chunk .+ failed./i.test(error.message)) {
		window.location.reload();
	}
});

const app = createApp(App)
	.use(router)
	.use(store)
	.use(apolloProvider)
	.use(auth0)
	.use(VueGtag, {
		config: { id: 'G-35X1ZJNG5Z' },
		pageTrackerTemplate(to) {
			return {
				page_title: to.meta.title,
				page_path: to.path
			};
		},
	}, router);

Sentry.init({
	app,
	integrations: [
		Sentry.browserTracingIntegration({ router }),
	],
	attachStacktrace: true,
	autoSessionTracking: true,
	debug: process.env.VUE_APP_SENTRY_DEBUG,
	dsn: process.env.VUE_APP_SENTRY_DSN,
	environment: process.env.VUE_APP_SENTRY_ENV,
	release: process.env.VUE_APP_SENTRY_RELEASE,
	tracesSampleRate: (process.env.VUE_APP_SENTRY_ENV !== 'PROD' ? 1 : 0.1),
	initialScope: {
		'tags': store.state.user.data.id ? { 'quu.user.id': store.state.user.data.id } : {}
	},
	ignoreErrors: [
		'userTokenException'
	]
});

// app.use(router);
app.mount('#app-root');
