import {GET_BILLS, GET_DOWNLOADABLE_BILLS, GET_TRANSACTIONS} from '../graphql/queries/property.js';
import store from './';
import {apolloClient} from '@/graphql/apollo';
import { expiredPrint } from '@/utilities';

const billCode = 90;

const transactionType = {
	10: {
		type: 'receipt',
		description: 'Payment received - thank you'
	},
	40: {
		type: 'refund',
		description: 'Refund'
	},
	50: {
		type: 'adjustment',
		description: 'Account adjustment'
	},
	80: {
		type: 'interest',
		description: 'Interest on overdue balance'
	},
	90: {
		type: 'bill',
		description: 'Bill issued'
	},
};

const defaultState = {
	all: {},
	financialYears: {},
};

export default {
	namespaced: true,

	state() {
		return Object.assign({}, defaultState);
	},
	mutations: {
		storeAll(state, value) {
			state.all[value.key] = value.value;
		},
		clear(state) {
			Object.assign(state, defaultState);
		}
	},
	getters: {
		latestBill: (state) => (attr) => {
			if (!state.all[attr.crn]) {
				store.dispatch('transaction/fetch', attr.crn);
				return false;
			}
			const propertyTransactions = state.all[attr.crn] || [];
			for (let transaction of propertyTransactions) {
				// Look for a normal bill. Interest bills will have a reasonCode, not normal bills
				if (transaction.type === transactionType[billCode]['type'] && (transaction.due || (!transaction.due && !expiredPrint(transaction.date)))) {
					return transaction;
				}
			}
 			return null;
		},
	},
	actions: {
		fetchBills({commit}, crn) {
			return apolloClient.query({
				query: GET_BILLS,
				variables: {crn: crn},
			}).then(response => {
				return response.data.bills.map(bill => {
					return {
						id: bill.uniqueId,
						amount: bill.billTotal,
						date: bill.dateBilled,
						displayDate: bill.datePrinted || bill.dateBilled,
						due: bill.dateDue,
						description: 'Bill number ' + bill.billNumber,
						// billedStateCode 0 = normal bills
						// billedStateCode 9 + billReasonCode not INT = adjustment bills (but not interest adjustments)
						// billedStateCode 1 + billReasonCode OCC = change of ownership bills
						type: (bill.billedStateCode === 0 || (bill.billedStateCode === 9 && bill.billReasonCode !== 'INT') || (bill.billedStateCode === 1 && bill.billReasonCode === 'OCC')) ? transactionType[billCode]['type'] : 'hidden',
						bill: bill.billNumber,
						reason: bill.billReasonCode,
						state: bill.billedStateCode
					};
				});
			}).catch(err => {
				return [];
			});
		},
		fetchDownloadableBills({commit}, crn) {
			return apolloClient.query({
				query: GET_DOWNLOADABLE_BILLS,
				variables: {crn: crn},
			}).then(response => {
				return response.data.downloadableBills.map(bill => {
					return {
						id: bill.hubId,
						amount: bill.billAmount,
						date: bill.billDate,
						due: bill.billDue,
						type: bill.billType,
						bill: bill.billNumber
					};
				});
			}).catch(err => {
				return [];
			});
		},
		fetchTransactions({commit}, crn) {
			return apolloClient.query({
				query: GET_TRANSACTIONS,
				variables: {crn: crn},
			}).then(response => {
				return response.data.transactions.map(transaction => {
					let typeCode = transaction.transactionTypeCode;
					return {
						id: transaction.uniqueId,
						amount: transaction.transactionValue,
						date: transaction.postedDate,
						displayDate: transaction.postedDate,
						description: transactionType[typeCode]['description'],
						type: transactionType[typeCode]['type'],
						bill: transaction.billNumber
					};
				});
			}).catch(err => {
				return [];
			});
		},
		async fetch({commit, state}, crn) {
			const allTypes = await Promise.all([
				this.dispatch('transaction/fetchTransactions', crn),
				this.dispatch('transaction/fetchBills', crn),
				this.dispatch('transaction/fetchDownloadableBills', crn),
			]);

			const transactions = allTypes[0];
			const bills = allTypes[1];
			const downloadableBills = allTypes[2];

			// Mark bills as downloadable if found in downloadableBills
			for (let downloadableBill of downloadableBills) {
				for (let bill of bills) {
					if (downloadableBill.bill === bill.bill) {
						bill.download = true;
					}
				}
			}

			// Mark adjustment transactions qualifying as interest
			const interestType = transactionType[80];
			for (let transaction of transactions) {
				if (transaction.bill && transaction.type === 'adjustment') {
					for (let bill of bills) {
						if (bill.type === 'hidden' && bill.bill === transaction.bill && bill.reason === 'INT') {
							transaction.type = interestType.type;
							transaction.description = interestType.description;
						}
					}
				}
			}

			// clean up "hidden" bills as they are not needed anymore
			const filteredBills = bills.filter(bill => bill.type !== 'hidden');

			// concat transactions+bills from array of arrays
			const all = [].concat.apply([], [transactions, filteredBills]);
			//sort all transactions and bills by date desc
			all.sort((a, b) => (a.date < b.date) ? 1 : -1);
			commit('storeAll', {key: crn, value: all});
		}
	}
};
