<template>
	<div id="app" :class="$route.name">
		<a href="#main" class="sr-only">Skip to main content</a>
		<div class="uu-myaccount">
			<uuMessageVue type="warning" v-if="environment !== 'PROD'">
				<uuIconVue icon="traffic-cone"/>
				<span>You are currently browsing a non production environment ({{ environment }})</span>
			</uuMessageVue>

			<uuFloatingMessageVue :overlapping="false" v-if="upcomingMaintenance">
				<uuIconVue icon="information"/>
				<span>{{ upcomingMaintenance }}</span>
			</uuFloatingMessageVue>

			<uu-container :size="`${$route.meta.smallContainer ? 's' : ''}`">
				<MyAccountError/>

				<header>
					<uu-grid flexible>
						<uu-cell class="v-c">
							<uuLogoVue class="no-grow" :linked="!underMaintenance"/>
						</uu-cell>

						<uu-cell class="v-c" no-pad v-if="!($route.name === 'home' || $route.meta.smallContainer)">
							<span class="h4 uu-brand"><b>My</b>Account</span>
						</uu-cell>
						<uu-cell class="v-c h-r">
							<div v-if="!isLoading && !$route.meta.smallContainer">
								<uuButtonVue v-if="isAuthenticated" @click="logout" label="Log out" icon="logout" className="outline small" id="header-logout"/>
							</div>
						</uu-cell>
					</uu-grid>
					<div class="quu-grid" v-if="showNav">
						<div class="cell xl id-nav">
							<uuNavigationVue openButtonLabel="Open Menu" closeButtonLabel="Close Menu" orientation="horizontal"/>
						</div>
					</div>
				</header>

				<uu-grid>
					<uu-cell size="xl">
						<main id="main" class="pad-b-xl">

							<uuLoadingVue v-if="isLoading" size="l"/>

							<RouterView v-slot="{ Component }">

								<KeepAlive include="MyAccountDashboard,PasswordOptions,SocialConnections,ContactDetails">
								<component :is="Component" ref="routerView" v-if="!loggingOutStatus" :key="id" />
								<uu-grid v-else>
									<uu-cell size="l" class="h-c">
										<uuLoadingVue size="l" :nopad="true" />
										<span class="quu-lead">Logging you out...</span>
									</uu-cell>
								</uu-grid>
							</KeepAlive>
						</RouterView>
						</main>
					</uu-cell>
				</uu-grid>
			</uu-container>

			<uuFooterVue :small="$route.meta.smallContainer">
				<uu-grid flexible>
					<uu-cell class="h-l2c">
						&copy; {{ new Date().getFullYear() }} <a href="https://www.urbanutilities.com.au" target="_blank" rel="noopener">Urban Utilities</a>
					</uu-cell>
					<uu-cell class="h-r h-r2c">
						<ul class="separated">
							<li><router-link :to="{ name: 'faq' }" v-slot="{ href, navigate }" custom><a :href="href" @click="navigate">FAQ</a></router-link></li>
							<li><router-link :to="{ name: 'privacy' }" v-slot="{ href, navigate }" custom><a :href="href" @click="navigate">Privacy</a></router-link></li>
							<li><a href="https://www.urbanutilities.com.au/about-us/corporate-information/website-terms-of-use" target="_blank" rel="noopener">Terms of use</a></li>
							<li><a href="https://www.urbanutilities.com.au/contact-us" target="_blank" rel="noopener">Contact us</a></li>
						</ul>
					</uu-cell>
				</uu-grid>
			</uuFooterVue>
		</div>
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import uuLogoVue from '@/components/uuLogoVue.vue';
import uuButtonVue from '@/components/uuButtonVue.vue';
import uuNavigationVue from '@/components/uuNavigationVue.vue';
import uuFooterVue from '@/components/uuFooterVue.vue';
import MyAccountError from '@/components/MyAccountError.vue';
import uuMessageVue from '@/components/uuMessageVue.vue';
import uuFloatingMessageVue from '@/components/uuFloatingMessageVue.vue';
import uuIconVue from '@/components/uuIconVue.vue';
import uuLoadingVue from '@/components/uuLoadingVue.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { ref, computed, onMounted, watch, nextTick } from 'vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

const { isAuthenticated, isLoading } = useAuth0();

const routeLoadedCheck = ref(false);
const refreshing = ref(false);
const registration = ref(null);
const updateExists = ref(false);
const routerView = ref(null);
const environment = process.env.VUE_APP_SENTRY_ENV;

// Computed
const id = computed(() => router.currentRoute.name);
const showNav = computed(() => !isLoading.value && isAuthenticated.value && !underMaintenance.value && !route.meta.smallContainer);

const loggingOutStatus = computed(() => store.getters['loggingOutStatus']);
const underMaintenance = computed(() => store.getters['underMaintenance']);
const upcomingMaintenance = computed(() => store.getters['upcomingMaintenance']);
const readyForSurvey = computed(() => store.getters['readyForSurvey']);

const setUpdateStatus = (e) => {
	registration.value = e.detail;
	updateExists.value = true;
};

const refreshApp = () => {
	updateExists.value = false;
	if (!registration.value || !registration.value.waiting) {
		return;
	}
	registration.value.waiting.postMessage('skipWaiting');
};

const logout = () => {
	store.dispatch('user/logout', true);
};

const routeLoaded = async () => {
	// Only run this once, and not on every route change
	if (routeLoadedCheck.value) {
		return;
	}
	routeLoadedCheck.value = true;
	const currentRoute = route.name;

	// When the router goes through a guard, the maintenance state is already set and this can be skipped
	if (underMaintenance.value === null) {
		try {
			await store.dispatch('checkMaintenance');
		} catch(e) {
			// Couldn't check maintenance, consider it's off.
		}
	}

	if (underMaintenance.value) {
		switch (currentRoute) {
		case 'siteAdmin':
		case 'telemetry':
		case 'status':
		case 'maintenance':
		case 'faq':
		case 'ddterms':
			break;
		default:
			router.push({name: 'maintenance'});
		}
		return;
	}

	// redirect maintenance route to home if maintenance is off
	if (currentRoute === 'maintenance') {
		router.push({name: 'home'});
	}

	if (!refreshing.value && currentRoute !== 'home' && isAuthenticated.value) {
		// fetch user data if not under maintenance, no update is available and user is authenticated
		store.dispatch('user/fetch', { clear: currentRoute === 'dashboard' }).catch(err => {
			// exception handled via globalError, no specific local error message here
		});

		store.dispatch('user/setUsabillaData');
	}
};

const setCanonical = () => {
	let canonical = document.querySelector('[rel="canonical"]');
	canonical.href = window.location.href;
};

store.commit('user/clearLogoutStatus', false);
store.commit('setOnlineStatus', window.navigator.onLine);

window.addEventListener('online', (e) => {
	store.commit('setOnlineStatus', true);
});

window.addEventListener('offline', (e) => {
	store.commit('setOnlineStatus', false);
});

// Global error handler, and redirect if 503 to maintenance
// use by all axios call (see apollo.js for the GraphQL version)
axios.defaults.mode = 'cors';
axios.defaults.timeout = 10000;
axios.interceptors.response.use(response => response, async (err) => {
	if (err.response) {
		await store.dispatch('handleError', {status: err.response.status, resource: err.response.request.responseURL});
	}
	return Promise.reject(err);
});

if ('serviceWorker' in navigator) {
	document.addEventListener('swUpdated', setUpdateStatus, {once: true});
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (refreshing.value) return;
		refreshing.value = true;
		window.location.reload();
	});
}

onMounted(() => {
	setCanonical();

	setTimeout(() => {
		if (router.currentRoute.name && router.currentRoute.name !== 'home' && updateExists.value) {
			refreshApp();
		}
	}, 1000);
});

watch(() => route.name, (to, from) => {
	if (to && to !== 'auth0-callback') {
		nextTick(routeLoaded);
	}

	if (to !== from) {
		if (updateExists.value) {
			refreshApp();
		}
		nextTick(function () {
			if (route.meta.title) {
				document.title = route.meta.title;
			}
			setCanonical();

			// trigger survey if page matches criterias
			if (route.meta.surveySafe && readyForSurvey.value) {
				window.usabilla_live('trigger', 'survey');
				store.commit('persistent/setSurvey', false);
			}

			setTimeout(() => {
				// routerView ref is gone when logging out
				if (routerView.value) {
					// Get component's "routeFocusTarget" ref.
					// If not existent, use router view container
					let focusTarget = (routerView.value.componentFocusTarget !== undefined)
						? routerView.value.componentFocusTarget
						: routerView.value.$el;

					// Target was a Vue component, get its DOM element
					if (!focusTarget?.tagName) {
						focusTarget = focusTarget?.$el;
					}

					if (focusTarget && focusTarget.setAttribute) {
						focusTarget.setAttribute('tabindex', '-1');
						focusTarget.focus({preventScroll: focusTarget.offsetTop > document.documentElement.scrollTop});
						// Reason: https://axesslab.com/skip-links/#update-3-a-comment-from-gov-uk
						focusTarget.removeAttribute('tabindex');
					}
				}
			}, 0);
		});
	}
}, {deep: true, immediate: true});
</script>

<style lang="scss">
[v-cloak] {
	display: none
}

uu-grid, uu-cell, div, h1, h2, h3, h4, h5, h6 {
	&:focus {
		outline: none;
	}
}

.uu-brand {
	position: relative;
	bottom: -.265rem;
	letter-spacing: -.05rem!important;
}

@media (max-width: 680px) {
	uu-logo svg *:not([fill]) {
		fill: transparent !important;
	}

	.home, .mfa {
		uu-logo svg *:not([fill]) {
			fill: currentColor !important;
		}
	}

	.uu-brand {
		margin-left: -12rem !important;
		margin-bottom: -.125rem !important;
		bottom: -.05rem;
	}
}

@media (max-width: 480px) {
	.uu {
		uu-logo {
			a {
				width: 9.5rem;
				height: 1.9rem;
				vertical-align: middle;
			}
		}

		.uu-brand {
			margin-left: -7.5rem !important;
			font-size: 1.5rem !important;
			bottom: -.05rem;
		}
	}
}

.uu-myaccount {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	> uu-container {
		padding-bottom: 2rem;
	}
}

@media print {

    .uu-root, .uu {
        width: 100%;
        margin: 0;
        font-size: 8pt;
    }

	* {
		color: black;
	}
	.uu-button, uu-navigation, .uu-footer ul, .no-print {
		display: none !important;
	}

	uu-logo svg * {
		fill: #000 !important;
	}

	uu-message {
		border: 1px dotted #000 !important;
	}

	uu-card, .card, .uu-form fieldset {
		border: 1px solid lightgray !important;
		page-break-inside: avoid;
	}
}
</style>
