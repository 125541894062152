import { useAuth0 } from '@auth0/auth0-vue';
import { watchEffect, ref } from 'vue';
import store from '@/store';
import jwtDecode from 'jwt-decode';

export const authGuard = (to, from, next) => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const watching = ref(true);

	const fn = async () => {
		if (isAuthenticated.value) {

			// Check if the property is in the store, otherwise redirect to homepage
			if (to.meta.propertyCheck ?? false) {
				if (!store.getters['property/propertyById'](to.params.id)) {
					return next({name: 'dashboard'});
				}
				return next();
			}

			if (to.meta.requiredPermission) {
				const token = await store.dispatch('user/userToken');
				const decoded = jwtDecode(token);
				const permissions = decoded.permissions || [];

				// assess permissions, e.g. admin route
				if (permissions?.includes(to.meta.requiredPermission)) {
					return next();
				} else {
					return next({name: 'dashboard'}); // denied
				}
			} else {
				// any user authenticated
				return next();
			}
		}

		// Redirect non logged in user to login, unless maintenance is on
		let redirectToLogin = true;
		store.dispatch('checkMaintenance').then(maintenance => {
			if (maintenance.maintenance) {
				switch (to.name) {
				case 'siteAdmin': // allow redirect to login if accessing the site admin
					break;
				default:
					redirectToLogin = false;
					return next({name: 'maintenance'});
				}
			}
		}).catch(e => {
			// Couldn't check maintenance status, consider off
		}).finally(() => {
			// Only redirect to login if the route is a maintenance exception (see above)
			if (redirectToLogin) {
				loginWithRedirect({appState: {target: to.fullPath}});
			}
		});
	};

	watchEffect(() => {
		if (!isLoading.value && watching.value) {
			watching.value = false;
			return fn();
		}
	});
};

export const redirectHome = (to, from, next) => {
	const { isAuthenticated, isLoading } = useAuth0();
	const watching = ref(true);

	const fn = function () {
		if (isAuthenticated.value) {
			return next({name: 'dashboard'});
		} else {
			return next();
		}
	};

	watchEffect(() => {
		if (!isLoading.value && watching.value) {
			return fn();
		}
	});
};
