import { createAuth0 } from '@auth0/auth0-vue';

const auth0Client = createAuth0({
	domain: process.env.VUE_APP_AUTH0_DOMAIN,
	clientId: process.env.VUE_APP_AUTH0_CLIENTID,
	authorizationParams: {
		redirect_uri: window.location.origin + '/auth0callback',
		audience: process.env.VUE_APP_TOKEN_AUDIENCE,
	}
});

export const auth0 = auth0Client;
