<template>
	<uu-logo>
		<router-link :to="{ name: (linked ? 'home' : route.name) }">
			<svg role="img" aria-label="Home - Urban Utilities logo">
				<use xlink:href="#logo-uu"></use>
			</svg>
		</router-link>
	</uu-logo>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
	linked: {
		type: Boolean,
		default: true
	},
});
</script>
