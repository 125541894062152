<template>
	<uuMessageVue type="error" v-if="msg" id="global-error">
		<uuIconVue icon="fault-triangle"/> <span v-html="msg"></span>
	</uuMessageVue>
</template>

<script setup>
import uuMessageVue from '@/components/uuMessageVue.vue';
import uuIconVue from '@/components/uuIconVue.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

const { error } = useAuth0();
const store = useStore();

const globalError = computed(() => store.state.globalError);
const msg = computed(() => error.value ? `${error.value}. Please try to <a href="/login">log in again</a>.` : globalError.value);
</script>
