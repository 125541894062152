<template>
	<component ref="root" :is="baseTag" :class="'uu-button ' + className" :href="href"
		:type="(type !== 'anchor') ? type ?? null : null" :aria-label="ariaLabel" :disabled="disabled ? 'true' : null"
		:aria-disabled="disabled ? 'true' : null" @click="emit('click', $event)">
		<uuIconVue v-if="icon" :icon="icon" size="sm" />
		<span>{{ currentLabel }}</span>
	</component>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import uuIconVue from '@/components/uuIconVue.vue';

const props = defineProps({
	type: {
		type: String,
		default: 'button'
	},
	label: {
		type: String,
		required: true
	},
	ariaLabel: {
		type: String
	},
	href: {
		type: String
	},
	errorLabel: {
		type: String
	},
	disabled: {
		type: Boolean,
		default: false
	},
	doneLabel: {
		type: String
	},
	className: {
		type: String,
		default: 'primary'
	},
	icon: {
		type: String
	}
});

const root = ref(null);
const currentLabel = ref(props.label);
const baseTag = computed(() => (props.type === 'anchor') ? 'a' : 'button');

const emit = defineEmits(['click']);

watchEffect(() => {
	currentLabel.value = props.label;
});

function preventDefault(e) {
	e.preventDefault();
}

const startLoading = () => {
	const self = root.value;
	if (!self) {
		return;
	}
	self.classList.add('loading');
	self.setAttribute('aria-disabled', true);
	self.setAttribute('aria-label', 'Loading');
	self.addEventListener('click', preventDefault);
};

const stopLoading = (error) => {
	const self = root.value;
	if (!self) {
		return;
	}
	let doneMessage = error ? props.errorLabel : props.doneLabel;
	if (!doneMessage) {
		doneMessage = props.label;
	}
	self.classList.remove('loading');
	self.removeAttribute('aria-disabled');
	self.setAttribute('aria-label', doneMessage);
	currentLabel.value = doneMessage;
	self.removeEventListener('click', preventDefault);
	setTimeout(() => {
		if (props.ariaLabel) {
			self.setAttribute('aria-label', props.ariaLabel);
		} else {
			self.removeAttribute('aria-label');
		}
		currentLabel.value = props.label;
	}, 5000);
};

defineExpose({
	startLoading,
	stopLoading,
});
</script>
