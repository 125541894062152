<template>
	<uu-navigation role="navigation" :class="extraClasses" :maxwidth="maxWidth" no-underline class="fullwidth" data-bg="urban-emerald">
		<button class="uu-nav-open" :aria-label="openButtonLabel" aria-expanded="false" :aria-controls="navId">
			<svg role="presentation" aria-hidden="true">
				<use xlink:href="#hamburger"></use>
			</svg>
		</button>
		<button class="uu-nav-close" :aria-label="closeButtonLabel" aria-hidden="true">
			<svg role="presentation" aria-hidden="true">
				<use xlink:href="#close"></use>
			</svg>
		</button>

		<uu-container size="m">
			<ul data-bg="urban-emerald" :id="navId">
				<li v-for="(nav, index) in navItems" v-bind:key="index">
					<router-link :to="{ name: nav.routeName ?? null }" v-slot="{ href, navigate, isExactActive }" v-if="isVisible(nav.permission)" custom>
						<template v-if="nav.routeName">
							<a :href=href @click="navigate" :aria-current="isExactActive ? 'page' : null">{{ nav.title }}</a>
							<span v-if="nav.subitems" tabindex="0" role="button" aria-expanded="false" aria-label="Title 3 menu"></span>
						</template>
						<span v-else :data-expandable="nav.subitems ? true : null">{{ nav.title }}</span>
					</router-link>
					<ul v-if="nav.subitems">
						<router-link v-for="(subnav, subindex) in nav.subitems" v-bind:key="subindex" :to="{ name: subnav.routeName}" v-slot="{ href, navigate, isExactActive }" custom>
							<li>
								<a :href=href @click="navigate" :aria-current="isExactActive ? 'page' : null">{{ subnav.title }}</a>
							</li>
						</router-link>

						<!-- <router-link v-for="(subnav, subindex) in nav.subitems" v-bind:key="subindex" :to=subnav.url v-slot="{ href, navigate, isExactActive }" custom>
							<li :class="isExactActive && 'active'">
								<a :href=href @click="navigate">{{ subnav.title }}</a>
							</li>
						</router-link> -->
					</ul>
				</li>
			</ul>
		</uu-container>
	</uu-navigation>
</template>

<script setup>
import { getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';

import jwtDecode from 'jwt-decode';

const props = defineProps({
	maxWidth: Number,
	extraClasses: String,
	openButtonLabel: {
		type: String,
		required: true
	},
	closeButtonLabel: {
		type: String,
		required: true
	}
});

const store = useStore();

const navId = `nav-${getCurrentInstance().uid}`;

const navItems = [
	{ title: 'Dashboard', routeName: 'dashboard' },
	{
		title: 'Login options',
		subitems : [
			{
				title: 'Email & Password',
				routeName: 'passwordOptions'
			},
			{
				title: 'Social connections',
				routeName: 'socialConnections'
			},
			{
				title: 'Delete your account',
				routeName: 'deleteAccount'
			},
		]
	},
	{ title: 'Contact details', routeName: 'contactDetails' },
	{ title: 'Site Admin', routeName: 'siteAdmin', permission: 'admin:site' },
	{ title: 'Telemetry', routeName: 'telemetry', permission: 'admin:site' },
	{ title: 'Accounts Admin', routeName: 'accountsAdmin', permission: 'admin:accounts' }
];

const isVisible = (permission) => {
	if (!permission) {
		return true;
	}
	const token = store.state.user.token; // Access token from store
	if (token) {
		const decoded = jwtDecode(token);
		return decoded?.permissions?.includes(permission);
	}
	return false;
};

onMounted(() => {
	window.uuNavigation();
});
</script>
