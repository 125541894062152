// import {ApolloClient} from 'apollo-client';
// import {HttpLink} from 'apollo-link-http';
// import {onError} from 'apollo-link-error';
// import {InMemoryCache} from 'apollo-cache-inmemory';
// import {setContext} from 'apollo-link-context';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import store from '../store';

const httpLink = new HttpLink({
	uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
	fetch: fetch
});

// Global error handling for GraphQL
// (see App.vue for the Axios/REST version)
const errorLink = onError((err) => {
	if (!err.networkError) {
		return;
	}
	// Query failed entirely, consider it's a 500
	if (!err.networkError.response) {
		err.networkError.response = {status: 500};
	}
	store.dispatch('handleError', { status: err.networkError.response.status, resource: err.operation.operationName });
});

const authLink = setContext(async (_, {headers}) => {
	// get the authentication token from local storage if it exists
	const token = await store.dispatch('user/userToken');
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			accept: 'application/json',
		}
	};
});
// Create the apollo client
export const apolloClient = new ApolloClient({
	link: authLink.concat(errorLink).concat(httpLink),
	cache: new InMemoryCache({
		addTypename: false
	}),
	connectToDevTools: true
});
