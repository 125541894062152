import {
	GET_USER,
	UPDATE_USER,
	GET_PERSONAL_DETAILS,
	REMOVE_USER
} from '../graphql/queries/user.js';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { toRaw } from 'vue';
import { apolloClient } from '@/graphql/apollo';
import { auth0 } from '@/auth/auth0';

const defaultData = {
	id: null,
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	mobile: '',
	preferredContact: 'Email'
};

const defaultState = {
	updated: null,
	sub: null,
	token: null,
	resetPasswordEmail: null,
	userEmail: null,
	isUU: false,
	isMfa: null,
	passwordlessEmail: null,
	identities: [],
	data: defaultData,
	usabillaData: {}
};

const validateToken = token => {
	const decoded = jwtDecode(token);
	let dateObj = new Date();
	let now = dateObj.getTime() / 1000;
	return (decoded.exp - 600) > now;
};

let refreshUser = null,
	defaultRefreshUserInterval = 3600000,
	faultRefreshUserInterval = 600000;

export default {
	namespaced: true,

	state() {
		return Object.assign({}, defaultState);
	},

	getters: {
		userData: state => {
			return state.data;
		},
		readyForSurvey: (state, getters, rootState) => {
			return () => document.body.clientWidth > 1024 && rootState.persistent.survey && state.usabillaData.sessionStart && state.usabillaData.sessionStart + 120000 < Date.now();
		}
	},

	mutations: {
		storeData(state, value) {
			value.metadata = null;
			state.data = { ...state.data, ...value };
		},
		storeToken(state, value) {
			state.token = value;
			state.updated = new Date;
		},
		storeSub(state, value) {
			state.sub = value;
		},
		storeDetails(state, payload) {
			state.resetPasswordEmail = payload.resetPasswordEmail;
			state.userEmail = payload.userEmail;
			state.isMfa = payload.isMfa;
			state.isUU = payload.isUU;
			state.passwordlessEmail = payload.passwordlessEmail;
			state.identities = payload.identities;
		},
		updateMfa(state, payload) {
			state.isMfa = payload.isMfa;
		},
		storeContacts(state, payload) {
			state.data.email = payload.email;
			state.data.phone = payload.phone;
			state.data.mobile = payload.mobile;
		},
		setUsabillaData(state, usabillaData) {
			state.usabillaData = usabillaData;
		},
		clear(state) {
			Object.assign(state, defaultState);
		},
		clearLogoutStatus(state) {
			this.commit('setLoggingOut', false);
			this.commit('setGlobalError', '');
		}
	},
	actions: {
		async userToken({ state }, options = {}) {
			if (!options.ignoreCache && state.token && validateToken(state.token)) {
				return state.token;
			}
			try {
				return await auth0.getAccessTokenSilently(options);
			} catch (e) {
				this.commit('setGlobalError', 'Your session has expired, please <a href="javascript:location.reload()">log in again</a>');
				throw ('userTokenException: login required');
			}
		},
		async fetch({ commit, dispatch, state }, payload) {
			if (payload?.clear) {
				this.commit('setClearing', true);
				this.commit('property/clear');
			}
			await apolloClient.query({
				query: GET_USER,
				fetchPolicy: 'no-cache'
			})
				.then(async response => {
					this.commit('setClearing', false);
					this.commit('setGlobalError', '');
					const userMetadata = response.data.user
						? response.data.user.metadata
						: [];
					this.dispatch(
						'property/createProperties',
						userMetadata
					);
					// Clone user data so we can safely delete metadata without messing with Apollo's response cache
					if (response.data.user) {
						const storedUserData = JSON.parse(
							JSON.stringify(response.data.user)
						);
						commit('storeData', storedUserData);
					}

					dispatch('setUsabillaData', {
						'auth0': state.sub,
						'email': state.data.email,
						'firstName': state.data.first_name,
						'lastName': state.data.last_name
					});

					clearTimeout(refreshUser);
					refreshUser = setTimeout(() => {
						this.dispatch('user/fetch').catch(err => {
							// exception handled via globalError, no specific local error message here
						});
					}, defaultRefreshUserInterval);
				}).catch(err => {
					clearTimeout(refreshUser);
					if (err.networkError && err.networkError.statusCode === 500) {
						refreshUser = setTimeout(() => {
							this.dispatch('user/fetch').catch(err => {
								// exception handled via globalError, no specific local error message here
							});
						}, faultRefreshUserInterval);
					}
					throw ({ error: 'fetch-error' });
				});
		},
		async update({ commit }, user) {
			await apolloClient
				.mutate({
					mutation: UPDATE_USER,
					variables: {
						phone: user.phone,
						mobile: user.mobile
						// preferredContact: user.preferredContact,
					}
				})
				.then(response => {
					const userContactDetails = response.data.updateUser.communication;
					commit('storeContacts', userContactDetails);
					if (user.success) {
						user.success();
					}
				})
				.catch(err => {
					if (user.error) {
						user.error();
					}
				});
		},
		async updateSubs({ commit }) {
			// grab an updated access_token before refreshing the user in the API
			const token = await this.dispatch('user/userToken', {
				ignoreCache: true
			});
			if (token) {
				commit('storeToken', token);
				await this.dispatch('user/fetch').catch(err => {
					// exception handled via globalError, no specific local error message here
				});
				this.commit('setGlobalError', '');
			}
		},
		async contactDetails({ commit }) {
			await apolloClient
				.query({
					query: GET_PERSONAL_DETAILS,
					fetchPolicy: 'network-only'
				})
				.then(response => {
					this.commit('setClearing', false);
					this.commit('setGlobalError', '');
					const userContactDetails = response.data.user.communication;
					commit('storeContacts', userContactDetails);
				})
				.catch(err => {
				});
		},
		async remove({ dispatch }, user) {
			await apolloClient
				.mutate({
					mutation: REMOVE_USER
				})
				.then(() => {
					dispatch('logout', false);
				})
				.catch(err => {
					if (user.error) {
						user.error();
					}
				});
		},
		async login({ commit, state, dispatch }) {
			const token = await dispatch('userToken');
			const user = auth0.user.value;
			const sub = user.sub;

			if (state.sub !== sub) {
				// new user logged in
				this.dispatch('clear');
				this.commit('setClearing', true);
			} else {
				this.commit('setClearing', false);
			}

			if (token) {
				commit('storeToken', token);
				commit('storeSub', sub);
				this.commit('setGlobalError', '');
			} else {
				this.commit(
					'setGlobalError',
					'There was an issue logging you in, please try again.'
				);
			}
		},
		async logout({ dispatch }, clearCache) {

			await this.dispatch('clear');
			this.commit('setLoggingOut', true);

			if (clearCache) {
				await dispatch('clearCache');
			}

			auth0.logout({
				returnTo: window.location.origin
			});
		},
		async clearCache() {
			let underMaintenance = false;

			try {
				await this.dispatch('checkMaintenance');
				underMaintenance = this.state.underMaintenance;
			} catch (e) {
				// Couldn't check maintenance, consider it's on
				underMaintenance = true;
			}
			if (!underMaintenance) {
				try {
					const token = await this.dispatch('user/userToken');
					await axios.post(
						`${process.env.VUE_APP_API_ENDPOINT}/user/logout`,
						{},
						{ headers: { 'content-type': 'application/json', authorization: `Bearer ${token}` }, responseType: 'json' }
					);
				} catch (e) {
					// Don't interrupt logout if user/userToken throws because the session has already expired
				}
			}
		},
		async setUsabillaData({ state, commit }, usabillaData) {
			const mergedData = { ...state.usabillaData, ...usabillaData };
			// Start a new usabilla session till next logout
			if (!mergedData.sessionStart) {
				mergedData.sessionStart = Date.now();
			}

			commit('setUsabillaData', mergedData);
			window.usabilla_live('data', {
				'custom': mergedData
			});
		}
	}
};
