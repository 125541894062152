import { toRaw } from 'vue';
import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router';
import Home from './views/MyAccountHome.vue';
import { authGuard, redirectHome } from './auth';
import store from './store';
import { useAuth0 } from '@auth0/auth0-vue';

let router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	hash: createWebHashHistory(process.env.BASE_URL),
	abstract: createMemoryHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { el: to.hash };
		}
		if (savedPosition) {
			return savedPosition;
		}
		return { top: 0 };
	},
	routes: [{
		path: '/index.html',
		redirect: '/'
	}, {
		path: '/',
		name: 'home',
		meta: {
			title: 'MyAccount - Urban Utilities'
		},
		component: Home,
		beforeEnter: redirectHome,
	}, {
		path: '/maintenance',
		name: 'maintenance',
		meta: {
			title: 'MyAccount - Maintenance in progress'
		},
		component: () =>
			import(/* webpackChunkName: "maintenance" */ './views/MyAccountMaintenance.vue')
	}, {
		path: '/admin/telemetry',
		name: 'telemetry',
		meta: {
			title: 'MyAccount - Telemetry',
			requiredPermission: 'admin:site'
		},
		beforeEnter: authGuard,
		component: () =>
			import(/* webpackChunkName: "telemetry" */ './views/admin/MyAccountTelemetry.vue')
	}, {
		path: '/status',
		name: 'status',
		meta: {
			title: 'MyAccount - Status',
		},
		component: () =>
			import(/* webpackChunkName: "status" */ './views/MyAccountStatus.vue')
	}, {
		path: '/mfa/:token?',
		name: 'mfa',
		meta: {
			title: 'Two-step verification',
			smallContainer: true
		},
		component: () =>
			import(/* webpackChunkName: "mfa" */ './views/MyAccountMfa.vue')
	}, {
		path: '/optout/:param1/:param2/:param3?',
		name: 'optout',
		meta: {
			title: 'Opt out of change notifications',
		},
		component: () =>
			import(/* webpackChunkName: "optout" */ './views/OptOut.vue')
	}, {
		path: '/deletion-status/:id?/:hash?',
		name: 'deletionStatus',
		meta: {
			title: 'Social user data deletion status',
			smallContainer: true
		},
		component: () =>
			import(/* webpackChunkName: "deletionStatus" */ './views/DeletionStatus.vue')
	}, {
		path: '/login',
		name: 'login',
		meta: {
			title: 'Login'
		},
		component: () =>
			import(/* webpackChunkName: "login" */ './views/MyAccountLogin.vue')
	}, {
		path: '/logout',
		name: 'logout',
		meta: {
			title: 'Logout'
		},
		component: () =>
			import(/* webpackChunkName: "logout" */ './views/MyAccounLogout.vue')
	}, {
		path: '/register',
		name: 'register',
		meta: {
			title: 'Register'
		},
		component: () =>
			import(/* webpackChunkName: "register" */ './views/MyAccounRegister.vue')
	}, {
		path: '/verified',
		name: 'verifyEmail',
		meta: {
			title: 'Verify your email'
		},
		component: () =>
			import(/* webpackChunkName: "verifyEmail" */ './views/VerifyEmail.vue')
	}, {
		path: '/social-signup',
		name: 'socialSignup',
		meta: {
			title: 'Social Signup'
		},
		component: () =>
			import(/* webpackChunkName: "socialSignup" */ './views/SocialSignup.vue')
	}, {
		path: '/faq',
		name: 'faq',
		meta: {
			title: 'Frequently Asked Questions'
		},
		component: () =>
			import(/* webpackChunkName: "faq" */ './views/MyAccountFaq.vue')
	}, {
		path: '/ddterms',
		name: 'ddterms',
		meta: {
			title: 'Direct Debit Terms and Conditions'
		},
		component: () =>
			import(/* webpackChunkName: "ddtc" */ './views/DDTermsConditions.vue')
	}, {
		path: '/privacy',
		name: 'privacy',
		meta: {
			title: 'Privacy collection notice'
		},
		component: () =>
			import(/* webpackChunkName: "privacy" */ './views/MyAccountPrivacy.vue')
	}, {
		path: '/dashboard',
		name: 'dashboard',
		meta: {
			title: 'Dashboard',
			surveySafe: true
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "dashboard" */ './views/MyAccountDashboard.vue'
			)
	}, {
		path: '/profile/social-connections',
		name: 'socialConnections',
		meta: {
			title: 'Social connections'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "social-connections" */ './views/profile/socialConnections.vue'
			)
	}, {
		path: '/profile/login-password',
		name: 'passwordOptions',
		meta: {
			title: 'Login & password options'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "login-password" */ './views/profile/passwordOptions.vue'
			)
	}, {
		path: '/profile/delete',
		name: 'deleteAccount',
		meta: {
			title: 'Delete account'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "delete-account" */ './views/profile/deleteAccount.vue'
			)
	}, {
		path: '/profile/contact-details',
		name: 'contactDetails',
		meta: {
			title: 'Contact details'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "contact-details" */ './views/profile/contactDetails.vue'
			)
	}, {
		path: '/property/add/:step?',
		name: 'propertyAdd',
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "property-add" */ './views/PropertyAdd.vue'
			)
	}, {
		path: '/property/:id',
		name: 'property',
		meta: {
			title: 'Property details',
			surveySafe: true,
			propertyCheck: true
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "property-view" */ './views/PropertyDetails.vue'
			)
	}, {
		path: '/property/:id/billing',
		name: 'propertyBilling',
		meta: {
			title: 'Property billing',
			propertyCheck: true
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "property-billing" */ './views/PropertyBilling.vue'
			)
	}, {
		path: '/change/:setting',
		name: 'change',
		meta: {
			title: 'Change your account settings'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "change" */ './views/MyAccountChange.vue'
			)
	}, {
		path: '/admin/site',
		name: 'siteAdmin',
		meta: {
			title: 'Site Administration',
			requiredPermission: 'admin:site'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "admin-site" */ './views/admin/SiteAdmin.vue'
			)
	}, {
		path: '/admin/accounts/:typeRef?/:typeVal?',
		name: 'accountsAdmin',
		meta: {
			title: 'Accounts Administration',
			requiredPermission: 'admin:accounts'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "admin-accounts" */ './views/admin/AccountsAdmin.vue'
			)
	}, {
		path: '/admin/problem-accounts',
		name: 'accountsAdminProblems',
		meta: {
			title: 'Accounts Problems',
			requiredPermission: 'admin:accounts'
		},
		beforeEnter: authGuard,
		component: () =>
			import(
				/* webpackChunkName: "admin-accounts" */ './views/admin/AccountsAdminProblems.vue'
			)
	}, {
		path: '/auth0callback',
		name: 'auth0-callback',
		meta: {
			title: 'MyAccount - Logging in...'
		},
		component: () =>
			import(/* webpackChunkName: "auth0callback" */ './views/Auth0Callback.vue')
	}, {
		path: '/:pathMatch(.*)*',
		name: 'error',
		meta: {
			title: 'Error'
		},
		component: () =>
			import(/* webpackChunkName: "error" */ './views/MyAccountError.vue')
	}]
});

router.beforeEach(async (to, from) => {
	// Trigger store user/login after auth0callback (skipping intermediate home redirect)
	if (from.name === 'auth0-callback' && to.name !== 'home') {
		const { isAuthenticated } = useAuth0();
		if (toRaw(isAuthenticated).value) {
			await store.dispatch('user/login');
		}
	}
});

export default router;
