<template>
	<uu-floating-message>
		<uu-message :class="extraClass" :no-overlap="overlapping ? null : true" solid :type="type">
			<slot></slot>
			<uuButtonVue className="close" label="" ariaLabel="Close the above message" icon="cross" @click="close" />
		</uu-message>
	</uu-floating-message>
</template>

<script setup>
import uuButtonVue from '@/components/uuButtonVue.vue';
import { getCurrentInstance } from 'vue';

const props = defineProps({
	extraClass: {
		type: String,
		default: ''
	},
	overlapping: {
		type: Boolean,
		default: true
	},
	type: {
		type: String,
		default: 'default'
	}
});

const emit = defineEmits(['closing']);
const message = getCurrentInstance();

const close = () => {
	// hiding the message is taken care of by droplets.js, but not removing it from the DOM
	setTimeout(() => {
		message.proxy.$el.style.display = 'none';
	}, 210);
	emit('closing');
};
</script>
