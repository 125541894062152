<template>
	<div class="home">

		<uuMessageVue v-if="isError || isInfo" :type="(isError ? 'warning' : null)">
			<uuIconVue :icon="isError ? 'fault-triangle' : 'information'"/> <span>{{ isError || '' }}{{ isInfo || '' }}</span>
		</uuMessageVue>

		<uu-hero-banner data-bg="urban-blue" class="fullwidth">
			<uu-container>
				<div>
					<h1>Welcome to MyAccount</h1>
					<p><uuButtonVue @click="login" label="Log in" id="home-login" /></p>

					<h2 class="h6">Don't have an account yet?</h2>
					<p><uuButtonVue className="link" @click="signup" label="Register now"></uuButtonVue>, it only takes a couple of minutes.</p>
				</div>
			</uu-container>
			<uu-ripple type="ripple-2">
				<svg role="presentation" aria-hidden="true">
					<use xlink:href="#ripple-2"></use>
				</svg>
			</uu-ripple>
		</uu-hero-banner>

		<uu-grid class="pad-t-xl">
			<uu-cell>
				<uuCardVue orientation="horizontal">

					<img src="/img/myaccount-1024.png"
					srcset="/img/myaccount-320.png 320w, /img/myaccount-640.png 640w, /img/myaccount-1024.png 1024w, /img/myaccount-1600.png 1600w"
					alt="Switch to email bills" sizes="100vw">

					<div>
						<h2 class="h3">Why register?</h2>

						<p>Quickly view and manage all your properties in one place.</p>

						<div class="uu-font-l">
							<uu-grid no-wrap flexible>
								<uu-cell no-min no-grow><uuIconVue icon="bill" size="xl" class="no-grow" /></uu-cell>
								<uu-cell class="v-c" max-width>View and pay bills</uu-cell>
							</uu-grid>
							<uu-grid no-wrap flexible>
								<uu-cell no-min no-grow><uuIconVue icon="technology" size="xl" class="no-grow" /></uu-cell>
								<uu-cell class="v-c" max-width>Switch to email or SMS bills</uu-cell>
							</uu-grid>
							<uu-grid no-wrap flexible>
								<uu-cell no-min no-grow><uuIconVue icon="finance" size="xl" class="no-grow" /></uu-cell>
								<uu-cell class="v-c" max-width>Setup Direct Debit</uu-cell>
							</uu-grid>
							<uu-grid no-wrap flexible>
								<uu-cell no-min no-grow><uuIconVue icon="customer" size="xl" class="no-grow" /></uu-cell>
								<uu-cell class="v-c" max-width>Change contact details</uu-cell>
							</uu-grid>
							<uu-grid>
								<uu-cell size="xl" class="h-r"><uuButtonVue @click="signup" label="Register now" id="home-signup" /></uu-cell>
							</uu-grid>
						</div>
					</div>
				</uuCardVue>
			</uu-cell>
		</uu-grid>
	</div>
</template>

<script setup>
import uuButtonVue from '@/components/uuButtonVue.vue';
import uuMessageVue from '@/components/uuMessageVue.vue';
import uuCardVue from '@/components/uuCardVue.vue';
import uuIconVue from '@/components/uuIconVue.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

const route = useRoute();
const store = useStore();
const { loginWithRedirect } = useAuth0();


const message = computed(() => route.query.message);
const isError = computed(() => {
	if (route.query.success === 'false' && message.value) {
		return message.value;
	}
	if (route.query.error && route.query.error_description) {
		return route.query.error_description;
	}
	return false;
});
const isInfo = computed(() => {
	if (route.query.success === 'true' && message.value) {
		return message.value;
	}
	return false;
});

store.commit('setLoggingOut', false);

const signup = async () => {
	await loginWithRedirect({ authorizationParams: { mode: 'signUp' } });
};
const login = async () => {
	await loginWithRedirect();
};
</script>
