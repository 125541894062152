import gql from 'graphql-tag';

export const GET_USER = gql`
	query User {
		user {
			id
			first_name
			last_name
			email
			metadata {
				personReference
				entityId
				entityName
				userEntityName
				customerReference
				dateBilled
				datePrinted
				dateDue
				directDebitDateDue
				billTotal
				isDueSoon
				isDue
				isOverdue
				isDirectDebit
				recoveryPath
				currentBalanceNet
				billingContactEmail
				billingContactPhone
				userOrder
				notifyEmails
				nonEBContact {
					billingAddressLine1
					billingAddressLine2
					billingAddressLine3
					billingAddressLine4
					billingAddressLine5
					postCode
				}
				customerClass
				customerAddress {
					customerAddressLine1
					customerAddressLine2
					customerAddressLine3
					customerAddressLine4
					customerAddressLine5
				}
				personList {
					personRole
					personReference
					personName
				}
			}
		}
	}
`;

export const GET_PERSONAL_DETAILS = gql`
	query User {
		user {
			id
			communication {
				phone
				mobile
			}
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUser($phone: String, $mobile: String) {
		updateUser(phone: $phone, mobile: $mobile) {
			communication {
				phone
				mobile
			}
		}
	}
`;

export const REMOVE_USER = gql`
	mutation removeUser {
		removeUser {
			id
		}
	}
`;
